<template>
  <div class="import import-users">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>
    <PageHeader
      v-model="query"
      :title="headerTitle"
      :has-back="true"
      :has-actions="!isFinish"
      :has-search="hasSearch"
      @back="goBack()"
    >
      <md-button
        v-if="isEditBtn"
        class="md-raised header-action header-action-icon"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        @click="onEditClick"
      >
        <MeepIconEdit class="app-icon" />
      </md-button>
      <md-button
        v-if="isDeleteBtn"
        class="md-raised header-action header-action-icon"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        @click="onDeleteClick"
      >
        <MeepIconActionDelete class="app-icon" />
      </md-button>

      <template #left>
        <div class="import__notifications">
          <h3>
            {{
              $t("import-users.notifications-text", {
                count: newUsersClone.length,
              })
            }}
          </h3>
        </div>
      </template>
    </PageHeader>
    <div class="import-content page-layout">
      <confirm-modal
        v-model="isConfirmModalOpen"
        :title="$t('import-users.modal-title')"
        :description="
          importErrorLine
            ? $t('import-users.error-modal-description')
            : $t('import-users.modal-description')
        "
        :question="
          importErrorLine
            ? $t('import-users.error-modal-question')
            : $t('import.modal-question')
        "
        :cta-yes="$t('confirm-action-modal.yes')"
        :cta-no="$t('confirm-action-modal.no')"
        @close="closeConfirmModal"
        @confirm="handleUpload"
      />
      <div class="import-new-card-container">
        <div class="import-new-card-container-header">
          <p class="import-new-card-error-line" v-if="importErrorLine">
            {{
              existed
                ? $t("import-users.user-existed-text")
                : $t("import-users.error-line")
            }}
          </p>

          <div
            class="import-new-card-container-header-companies"
            @click="openSelectCompanyModal"
          >
            <md-field>
              <label
                class="meep-select-label"
                :class="{ 'select-error': errors.length > 0 }"
              >
                {{
                  $t(
                    isJEPA ? "create-user.linkUser" : "create-user.linkCompany"
                  )
                }}
                *
              </label>
              <md-input
                v-if="selectedCompanies.length"
                v-model="selectedCompanies[0].name"
                name="trialPeriod"
                type="text"
                disabled
              />
            </md-field>
          </div>
        </div>

        <!-- sortable list -->
        <sortable-list
          class="page-list"
          default-sort="name"
          default-sort-order="asc"
          :selectable="isFinish ? 'single' : 'multiple'"
          :parameters="listParameters"
          :items="getSearchs"
          @selected="onSelect"
        >
        </sortable-list>
        <div class="md-layout import-buttons md-alignment-center-right">
          <md-button class="md-primary--inverted" @click="goBack()">
            Recommencer
          </md-button>
          <md-button
            v-if="!isFinish"
            class="md-primary"
            @click.native="showConfirmModal()"
          >
            {{ $t("import-users.import-button") }}
          </md-button>
          <md-button v-else class="md-primary" @click="goBack()">
            Terminer
          </md-button>
        </div>
      </div>
    </div>
    <edit-modal
      v-if="isEditModal"
      typeCom="editUser"
      @close="onCloseModal"
      @delete="onConfirmDelete"
      :dataEdit="userSelected"
    ></edit-modal>
    <confirm-action-modal
      v-if="isConfirmDelete"
      :text="confirmDeleteText"
      :object-to-act-upon="{}"
      @close="isConfirmDelete = false"
      @confirm="onConfirmDelete"
    />
    <select-items-modal
      v-if="isSelectCompanyModalOpen"
      select-type="single"
      :items-list="companies"
      :selected-list="selectedCompanies"
      :text="selectGroupesModalText"
      @close="closeSelectCompanyModal"
      @saved="onCompanySelected"
    >
      <template #icon>
        <MeepIconSettingCompanies class="app-icon-color" />
      </template>
    </select-items-modal>
  </div>
</template>

<script>
import _isEqual from "lodash/isEqual";
import _cloneDeep from "lodash/cloneDeep";
import { mapActions, mapGetters } from "vuex";
import Fuse from "fuse.js";

import PageHeader from "@/components/PageHeader";
import ConfirmModal from "../../components/modal/ConfirmModal";
import Banner from "@/components/Banner";
import EditModal from "@/components/modal/EditModal.vue";
import ConfirmActionModal from "@/components/modal/confirm-action.vue";

import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";
import MeepIconEdit from "@/components/icons/MeepIconEdit.vue";
import MeepIconActionDelete from "@/components/icons/MeepIconActionDelete.vue";
import MeepIconSettingCompanies from "@/components/icons/MeepIconSettingCompanies.vue";

import usersModel from "@/model/users";
import companiesModel from "@/model/companies";

import HasBackMixin from "@/mixins/HasBackMixin";
import selectItemsModal from "@/components/modal/select-items";

export default {
  name: "ImportUsers",

  components: {
    PageHeader,
    ConfirmModal,
    Banner,
    "select-items-modal": selectItemsModal,
    MeepIconSettingCompanies,
    EditModal,
    MeepIconSettings,
    MeepIconActionDelete,
    MeepIconEdit,
    ConfirmActionModal,
  },

  mixins: [HasBackMixin],

  data() {
    return {
      query: "",
      createUser: true,
      isConfirmModalOpen: false,
      isConfirmDelete: false,
      isEditModal: false,
      isFinish: false,
      existed: false,
      userSelected: {},
      listUserSelected: [],
      confirmDeleteText: {},
      newUsersClone: [],
      companies: [],
      selectedCompanies: [],
      selectedCompaniesClone: [],
      isSelectCompanyModalOpen: false,

      selectGroupesModalText: {
        header: "Sélectionner l’entreprise à lier",
      },
    };
  },

  computed: {
    ...mapGetters([
      "isAdmin",
      "isCollab",
      "isClient",
      "isJEP",
      "isJEPA",
      "newUsers",
      "importErrorLine",
    ]),

    isAnyError() {
      const newArr = this.newUsersClone.filter(item => item.messageError);
      return !!newArr.length;
    },

    headerTitle() {
      return this.isFinish
        ? this.$t("import.results-title")
        : this.$t("import-users.importer-title");
    },

    isImportEmloyee() {
      return this.$route.query.type === "employee";
    },

    hasSearch() {
      return this.newUsersClone.length !== 0;
    },

    listParameters() {
      const params = [
        {
          name: "Nom et prénom",
          key: "name",
        },
        {
          name: "Adresse mail",
          key: "data.email",
        },
        {
          name: "Adresse postale",
          key: "data.adresse",
        },
        {
          name: "Ville",
          key: "data.city",
        },
        {
          name: "Code postal",
          key: "data.codepostal",
        },
        {
          name: "Téléphone fixe",
          key: "data.telfixe",
        },
        {
          name: "Téléphone portable",
          key: "data.telportable",
        },
        {
          name: "Validation",
          format: (_, item) => {
            return item.messageError ? item.messageError : item.status;
          },
        },
      ];
      if (this.isImportEmloyee) {
        params.splice(2, 0, {
          name: "Numéro de sécurité sociale",
          key: "data.socialSecurityNumber",
        });
      }

      return params;
    },

    confirmModalText() {
      return {
        header: this.$t("import-users.import-title"),
        question: this.$t("import.confirm-modal.description"),
      };
    },
    canChangeUserStatus() {
      return this.isAdmin || this.isClient || this.isCollab;
    },

    templateLink() {
      return this.canChangeUserStatus
        ? "/templates/meep_users_template.xlsx"
        : "/templates/meep_users_template_normal.xlsx";
    },

    getSearchs() {
      if (this.query === "") {
        return this.newUsersClone;
      }
      const fuse = new Fuse(this.newUsersClone, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        keys: ["name"],
      });
      return fuse.search(this.query).map(item => item.item);
    },

    isEditBtn() {
      return this.listUserSelected.length === 1;
    },

    isDeleteBtn() {
      return this.listUserSelected.length > 0;
    },
  },

  async mounted() {
    if (!this.newUsers.length) {
      this.$router.push("/dashboard/setting/import");
    }
    this.companies = await companiesModel.getAll();

    this.newUsersClone = _cloneDeep(this.newUsers);
  },

  methods: {
    ...mapActions(["SET_IMPORT_USERS", "SET_IMPORT_ERROR_LINE"]),

    onConfirmDelete() {
      let newUsersCloneArray = _cloneDeep(this.newUsersClone);

      for (let i = 0; i < this.listUserSelected.length; i++) {
        newUsersCloneArray = newUsersCloneArray.filter(
          item => !_isEqual(this.listUserSelected[i], item)
        );
      }
      if (newUsersCloneArray.length === 0) {
        this.$router.push("/dashboard/setting/import");
      }
      this.newUsersClone = newUsersCloneArray;
    },

    onSelect(item) {
      this.listUserSelected = item;
      this.userSelected = item[0];
    },

    onEditClick() {
      this.isEditModal = true;
    },

    onDeleteClick() {
      const isSingle = this.listUserSelected.length === 1;
      this.confirmDeleteText = {
        header: isSingle
          ? this.$t("list-user.delete-modal-title-single")
          : this.$t("list-user.delete-modal-title-plural"),
        body: () => {
          return isSingle
            ? this.$t("list-user.delete-modal-body-single", {
                fullName: this.userSelected.name,
              })
            : this.$t("list-user.delete-modal-body-plural", {
                count: this.listUserSelected.length,
              });
        },
        question: this.$t("list-user.modal-confirm-cta"),
      };
      this.isConfirmDelete = true;
    },

    onCloseModal(data) {
      if (!data) {
        this.isEditModal = false;
      } else {
        this.saveData(data);
        this.isEditModal = false;
      }
    },

    saveData(data) {
      this.userSelected.data = data;
    },

    async handleUpload() {
      this.isConfirmModalOpen = false;
      let newData = [];

      newData = await Promise.all(
        this.newUsersClone.map(async user => {
          if (user.isValid) {
            try {
              user.data.createUser = this.createUser;
              await usersModel.create(user.data);
              user.status = "L'utilisateur a bien été créé";
            } catch (err) {
              if (err.status === 400) {
                this.existed = true;
              }
              if (!user.messageError) {
                user.messageError = err.msg;
              }
              this.SET_IMPORT_ERROR_LINE(true);
            }
          }
          return user;
        })
      );
      this.newUsersClone = _cloneDeep(this.newUsersClone);

      this.SET_IMPORT_USERS(newData);

      this.isFinish = true;
    },

    showConfirmModal() {
      this.isConfirmModalOpen = true;
    },

    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },

    onCompanySelected(companies) {
      this.selectedCompanies = companies;
      this.selectedCompaniesClone = [...this.selectedCompanies];
      this.newUsersClone.map(user => {
        user.data.company_ids = [companies[0].id];
      });
    },

    closeSelectCompanyModal() {
      this.isSelectCompanyModalOpen = false;
    },
    openSelectCompanyModal() {
      this.isSelectCompanyModalOpen = true;
    },
  },

  watch: {
    isAnyError: function(newValue) {
      if (!newValue) {
        this.SET_IMPORT_ERROR_LINE(false);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

.import-users {
  .new-users {
    padding: 0;
    font-size: 18px;

    .header {
      font-weight: bold;
      display: flex;
      align-items: center;
    }

    .md-layout {
      padding: 0;

      &.header {
        padding: 15px;
      }
    }

    .md-indeterminate {
      margin-top: 10px;
    }

    .md-table-row {
      margin: 0;

      .md-layout-item {
        padding: 35px 20px;
      }
    }

    .error-row {
      .md-layout-item {
        color: #ffffff;
        border: none;
      }
    }
  }

  &__notifications {
    h3 {
      font-family: Montserrat-Bold;
    }

    p {
      width: 50%;
      color: red;
    }
  }
  .page-layout {
    height: 100%;
    .import-new-card-container {
      &-header {
        display: flex;

        .import-new-card-error-line {
          flex: 1 1 50%;
          background: var(--bg-primary);
          padding: 20px 27px;
          color: var(--text-primary);
          font-family: var(--font-bold);
          font-size: toRem(14);
          border-radius: 18px;
          margin-right: 10px;

          @include for-lg {
            font-size: toRem(17);
          }
        }

        &-companies {
          flex: 1 1 20%;
          padding: 18px;
          margin-left: auto;
          margin-right: 12px;
          max-width: 285px;
          border-radius: 10px;
          background: white;
          cursor: pointer;

          .md-field {
            margin: 0;
            label {
              top: 20px;
              font-size: 15px;
            }

            &::after {
              background: none;
            }

            &.md-has-value {
              padding-top: 26px;

              label {
                top: 5px;
              }
            }
          }

          .md-input {
            cursor: pointer;
          }

          @include for-lg {
            margin-right: 10px;
            max-width: 465px;

            .md-field {
              label {
                top: 15px;
              }

              &.md-has-value {
                padding-top: 16px;

                label {
                  top: 0px;
                }
              }
            }
          }
        }
      }

      .sortable-list {
        .md-table-content {
          .md-table-head:last-child {
            .md-table-head-container {
              width: 280px;
            }
          }
          .md-table-cell-selection {
            .md-table-cell-container {
              margin: 0 20px;
            }
          }
        }
      }

      .import-buttons {
        margin: 20px 16px;
        .md-button {
          min-width: 180px;
        }
      }
    }
  }
}
</style>

<template>
  <!-- The Modal -->
  <div class="edit-modal modal">
    <!-- Modal content -->
    <div class="modal-content">
      <div class="edit-header">
        <div
          :class="isGC ? 'app-logo-minified' : 'app-logo'"
          v-html="isGC ? logoMinified : logo"
        />

        <div class="edit-header-title">
          <slot name="icon"><MeepIconExclamationMark class="app-icon"/></slot>
          <p class="edit-header-title__text">
            {{
              isEditUser
                ? this.$t("users.view.title")
                : this.$t("information-company.title")
            }}
          </p>
        </div>
      </div>
      <div class="edit-body">
        <UserForm
          v-if="typeCom === 'editUser'"
          :userData="user"
          :oldUserData="oldUser"
          ref="userForm"
        />
        <CompanyForm
          v-else
          :companyData="company"
          :oldUserData="oldCompany"
          ref="companyForm"
        />
      </div>
      <div class="edit-footer">
        <md-button class="md-primary--inverted" @click.native="onCancelClick">
          {{ $t("edit-modal.cancel-button") }}
        </md-button>

        <md-button class="md-raised md-alternate" @click.native="onDeleteClick">
          {{ $t("edit-modal.delete-button") }}
        </md-button>

        <md-button class="md-raised md-primary" @click.native="onConfirmClick">
          {{ $t("edit-modal.confirm-edit-button") }}
        </md-button>
      </div>
    </div>
    <confirm-action-modal
      v-if="isConfirmModalOpen"
      :text="confirmModalText"
      :object-to-act-upon="{}"
      @close="isConfirmModalOpen = false"
      @confirm="onConfirmModal"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

import { USERS, MODALS } from "@/constants";
import { sortByKey } from "@/services/util";

import companiesModel from "@/model/companies";
import groupesModel from "../../model/groupes";

import ConfirmActionModal from "./confirm-action.vue";
import UserForm from "@/routes/users/UserForm.vue";
import CompanyForm from "@/routes/companies/CompanyForm.vue";

import MeepIconExclamationMark from "@/components/icons/MeepIconExclamationMark.vue";

export default {
  name: "EditModal",

  components: {
    MeepIconExclamationMark,
    ConfirmActionModal,
    UserForm,
    CompanyForm,
  },

  props: {
    dataEdit: {
      type: Object,
      default: () => {},
    },
    typeCom: {
      type: String,
      default: "editUser",
    },
  },

  data() {
    return {
      isLoading: false,
      isConfirmModalOpen: false,
      confirmationContext: "trash",
      newName: "",
      groups: [],
      isValid: true,
      user: {
        scope: this.isClient ? USERS.EMPLOYEE : null,
        gender: null,
        firstname: "",
        lastname: "",
        socialSecurityNumber: "",
        birthday: null,
        email: "",
        telfixe: "",
        telportable: "",
        adresse: "",
        city: "",
        country: "",
        codepostal: null,
        department: "",
        company_ids: [],
        hireDate: null,
        contractType: "",
        salary: null,
        trialPeriod: null,
      },
      oldUser: {},
      companies: [],
      company: {
        siren: "",
        name: "",
        email: "",
        tel: "",
        url: "",
        adresse: "",
        ville: "",
        firstName: "",
        lastName: "",
        codepostal: null,
        groups: [],
        cloud_synchronize_email: "",
      },
      oldCompany: {},
    };
  },

  computed: {
    ...mapGetters([
      "isAdmin",
      "isCollab",
      "isClient",
      "logo",
      "logoMinified",
      "isJEPA",
      "isGC",
    ]),

    availableScopes() {
      if (this.isJEPA) {
        return [USERS.CLIENT];
      }

      const result = [USERS.EMPLOYEE];

      if (this.isAdmin || this.isCollab) {
        result.push(USERS.COLLAB, USERS.CLIENT);
      }

      return result;
    },

    confirmModalText() {
      if (this.isEditUser) {
        switch (this.confirmationContext) {
          case MODALS.DELETE:
            return {
              header: this.$t("list-user.delete-modal-title-single"),
              body: () => {
                return this.$t("list-user.delete-modal-body-single", {
                  fullName: this.dataEdit.name,
                });
              },
              question: this.$t("list-user.modal-confirm-cta"),
            };
          case MODALS.SAVE:
            return {
              header: this.$t("users.view.modal-title"),
              body: () => {
                return this.$t("user.view-update-modal.body");
              },
              question: this.$t("users.view.modal-question"),
            };
          default:
            return {
              header: this.$t("users.view.modal-title"),
              body: () => {
                return this.$t("users.view.edit-modal-body");
              },

              question: this.$t("users.view.edit-modal-question"),
            };
        }
      }

      const title = "d'une entreprise";
      switch (this.confirmationContext) {
        case MODALS.DELETE:
          return {
            header: this.$t("list-company.trash-header", { title: title }),

            body: () => {
              return this.$t("list-company.trash-single", {
                fullName: this.dataEdit.name,
              });
            },

            question: this.$t("list-company.question"),
          };
        case MODALS.SAVE:
          return {
            header: this.$t("companies.view.update-modal.header"),
            body: () => {
              return this.$t("companies.view.update-modal.body");
            },
            question: this.$t("companies.view.update-modal.question"),
          };
        default:
          return {
            header: this.$t("companies.view.update-modal.header"),
            body: () => {
              return this.$t("companies.view.update-modal.body");
            },
            question: this.$t("companies.view.update-modal.question"),
          };
      }
    },

    isEditUser() {
      return this.typeCom === "editUser";
    },
  },

  created() {
    this.loadGroups();
    this.loadCompanies();
  },

  mounted() {
    this.isLoading = true;
    this.oldUser = _.cloneDeep(this.dataEdit.data);
    this.convertData(this.dataEdit.data);
    this.isLoading = false;
  },

  methods: {
    convertData(data) {
      if (!this.isEditUser) {
        this.company = _.cloneDeep(this.dataEdit.data);
        this.$set(this.company, "firstname", data.firstName);
        this.$set(this.company, "lastname", data.lastName);
      } else {
        this.user = { ...this.user, ...this.dataEdit.data };
      }
    },
    async loadCompanies() {
      const companies = await companiesModel.getAll();
      this.companies = await sortByKey(companies, "name");
    },
    async loadGroups() {
      try {
        this.isLoading = true;
        this.groups = await groupesModel.getAll();
        this.isLoading = false;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });

        this.isLoading = false;
      }
    },
    goBack() {
      window.history.back();
    },

    checkEmptyValue(data) {
      return !data;
    },
    filterVariable(data) {
      if (this.typeCom === "editUser") {
        return _.omit(data, ["siren", "tel", "url", "group"]);
      } else {
        return _.omit(data, ["telfixe", "telportable", "company_ids"]);
      }
    },
    formatObjectCompany(data) {
      data["firstName"] = data["firstname"];
      data["lastName"] = data["lastname"];
      return _.omit(data, ["lastname", "firstname"]);
    },
    compareObj() {
      const cloneData = _.cloneDeep(this.user);
      if (!this.oldUser?.company_ids && this.typeCom === "editUser") {
        delete cloneData.company_ids;
        return _.isEqual(this.oldUser, this.filterVariable(cloneData));
      }
      if (!this.oldUser?.groups && this.typeCom === "editCompany") {
        cloneData["firstName"] = cloneData["firstname"];
        cloneData["lastName"] = cloneData["lastname"];
        const result = _.omit(cloneData, ["lastname", "firstname", "groups"]);
        return _.isEqual(this.oldUser, this.filterVariable(result));
      }
      if (this.typeCom === "editUser") {
        return _.isEqual(this.oldUser, this.filterVariable(cloneData));
      }
      return _.isEqual(
        this.oldUser,
        this.filterVariable(this.formatObjectCompany(cloneData))
      );
    },

    removeCompany(companyId) {
      this.user.company_ids = this.user.company_ids.filter(
        company => company !== companyId
      );
    },

    getCompanyName(companyId) {
      const company = this.companies.find(company => company.id === companyId);
      return company ? company.name : "";
    },

    openConfirmModal() {
      this.isConfirmModalOpen = true;
    },

    // confirm change value
    onConfirmClick() {
      this.confirmationContext = MODALS.SAVE;
      this.openConfirmModal();
    },

    onDeleteClick() {
      this.confirmationContext = MODALS.DELETE;
      this.openConfirmModal();
    },

    onCancelClick() {
      if (this.compareObj()) {
        this.$emit("close");
      } else {
        this.confirmationContext = MODALS.TRASH;
        this.openConfirmModal();
      }
    },
    onConfirmModal() {
      switch (this.confirmationContext) {
        case MODALS.SAVE:
          this.confirmEdit();
          break;
        case MODALS.DELETE:
          this.confirmDelete();
          break;
        default:
          this.$emit("close");
      }
    },

    // confirm action edit information user
    async confirmEdit() {
      if (this.isEditUser) {
        this.$refs.userForm.$v.$touch();
      }

      this.isValid = this.isEditUser
        ? !this.$refs.userForm.$v.$invalid
        : await this.$refs.companyForm.$validator.validateAll();

      if (this.isValid) {
        if (this.isEditUser) {
          this.dataEdit.name = `${this.user.firstname} ${this.user.lastname}`;
        } else {
          this.newName = this.company.name;

          this.$emit("change-name", this.newName);
        }
        this.dataEdit.isValid = true;
        this.dataEdit.errors = [];
        this.dataEdit.messageError = "";
        this.dataEdit.status = "Ok!";

        this.$emit(
          "close",
          this.filterVariable(this.isEditUser ? this.user : this.company)
        );
      } else {
        this.$toasted.global.AppError({
          message: this.$t("error.required"),
        });
      }
    },

    confirmDelete() {
      this.$emit("delete");
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .edit-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100vw;
    height: calc(100vh - 50px);
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0.28s ease;

    .modal-content {
      display: flex;
      flex-direction: column;
      background-color: #fefefe;
      margin: auto;
      padding: 0 30px 30px 30px;
      border: 1px solid #888;
      width: 60%;
      height: 80%;
      border-radius: 27px;

      .edit-header {
        .app-logo {
          svg {
            width: 105px;
          }
        }

        &-title {
          display: flex;
          justify-content: center;
          margin-bottom: toRem(62);
          align-items: center;
          line-height: 1;
          font-size: var(--modal-title-size-lg);
          font-family: var(--font-bold);
          letter-spacing: -1.18px;
          @include for-lg {
            font-size: var(--modal-title-size-xl);
          }
          &__text {
            margin-left: toRem(17);
          }
        }
      }

      .edit-body {
        overflow: auto;

        .meep-form {
          &.md-card {
            padding: 0 20px;
            margin: 0;
          }

          .md-field {
            margin: 4px 0 15px;
          }
        }
      }
      .edit-footer {
        margin-top: toRem(62);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .select-error {
      color: var(--primary, #ff1744);

      &--small {
        font-size: 12px;
      }
    }
  }
  .empty-value {
    background-color: var(--bg-primary);
  }
}
</style>

<template>
  <md-dialog
    :md-active="value"
    @md-close="closeModal"
    @md-clicked-outside="closeModal"
    class="confirm-modal"
  >
    <div class="confirm-modal-header">
      <div
        :class="isGC ? 'app-logo-minified' : 'app-logo'"
        v-html="isGC ? logoMinified : logo"
      />
    </div>
    <div class="confirm-modal-body">
      <div class="confirm-modal-body-title">
        <slot name="icon"
          ><MeepIconExclamationMark :class="styleClassIcon"
        /></slot>
        {{ title }}
      </div>
      <div class="confirm-modal-body-content">
        <p class="confirm-modal-body-context">{{ description }}</p>
        <p class="confirm-modal-body-question">{{ question }}</p>
      </div>
    </div>
    <div class="confirm-modal-buttons">
      <md-button class="md-primary--inverted" @click="$emit('close')">{{
        ctaNo
      }}</md-button>
      <md-button class="md-secondary" @click="$emit('confirm')">{{
        ctaYes
      }}</md-button>
    </div>
  </md-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import MeepIconExclamationMark from "@/components/icons/MeepIconExclamationMark.vue";

export default {
  name: "ConfirmModal",
  components: {
    MeepIconExclamationMark,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    ctaYes: {
      type: String,
      default: "",
    },
    ctaNo: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      required: true,
    },
    question: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["logo", "logoMinified", "isGC", "isJEPA"]),
    styleClassIcon() {
      return {
        "app-icon": true,
        "app-icon--jepa": this.isJEPA,
      };
    },
  },
  methods: {
    closeModal() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
@import "../../styles/color";
.confirm-modal {
  font-family: inherit;
  bottom: 50px;

  .md-dialog-container {
    min-width: 50%;
    padding: 0 0 toRem(45) toRem(27);
    box-shadow: none;
    border-radius: 27px;
    @include for-lg {
      min-height: 350px;
      padding: toRem(10) toRem(64) toRem(60) toRem(38);
    }
  }

  &-header {
    .app-logo {
      svg {
        width: 105px;
      }
    }
  }

  &-body {
    padding: 0 20px;

    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: var(--modal-title-size-lg);
      font-family: var(--font-bold);
      margin-bottom: 15px;
      @include for-lg {
        font-size: var(--modal-title-size-xl);
      }
      .app-icon {
        width: var(--modal-icon-size-lg);
        height: var(--modal-icon-size-lg);
        margin-right: 15px;
        &--jepa {
          text {
            fill: $cl-yellow;
          }
          path {
            stroke: $cl-yellow;
          }
        }
        @include for-lg {
          width: var(--modal-icon-size-xl);
          height: var(--modal-icon-size-xl);
        }
      }
      .ellipse-78 {
        width: 88.5px;
        height: 88.5px;
        border-radius: 50%;
        border: solid 8px #f3d56d;
        background-color: #fff;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-context {
      font-family: var(--font-bold);
      font-size: toRem(14);
      margin-bottom: 10px;
      @include for-lg {
        font-size: toRem(22);
      }
      &-loading {
        font-size: toRem(14);
        font-weight: 500;
        color: #032575;
        @include for-lg {
          font-size: toRem(22);
        }
      }
    }

    &-question {
      font-size: 17px;
      font-family: var(--font-x);
    }
  }

  &-buttons {
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .md-secondary {
      min-width: 80px;
    }
  }

  .confirmModal__description {
    padding: 0 20px;
  }

  .md-dialog-actions {
    padding: 8px 26px 21px 24px;
  }
}
</style>

<template>
  <div>
    <LoadingCard v-if="isLoading" />
    <md-card v-else class="meep-form">
      <slot name="header"></slot>
      <!-- le formulaire de création -->
      <md-card-content>
        <!-- Numéro de SIREN -->
        <div class="meep-form-column">
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('siren') }">
              <label>{{ $t("create-company.siren") }}</label>

              <md-input
                v-model="company.siren"
                v-validate="'required|numeric|min:9|max:9'"
                name="siren"
                type="text"
              >
              </md-input>

              <span v-show="errors.has('siren')" class="md-error">
                {{ errors.first("siren") }}
              </span>
            </md-field>
          </div>

          <!-- Nom -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('name') }">
              <label>{{ $t("create-company.name") }}</label>

              <md-input
                v-model="company.name"
                v-validate="{
                  required: true,
                }"
                name="name"
                type="text"
              >
              </md-input>

              <span v-show="errors.has('name')" class="md-error">
                {{ errors.first("name") }}
              </span>
            </md-field>
          </div>

          <!-- Nom et prénom du dirigeant -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('firstName') }">
              <label>{{
                editRequiredForm($t("create-company.firstName"))
              }}</label>

              <md-input
                v-model="company.firstName"
                v-validate="{
                  alpha_spaces: true,
                }"
                name="firstName"
                type="text"
              >
              </md-input>

              <span v-show="errors.has('firstName')" class="md-error">
                {{ errors.first("firstName") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('lastName') }">
              <label>{{
                editRequiredForm($t("create-company.lastName"))
              }}</label>

              <md-input
                v-model="company.lastName"
                v-validate="{
                  alpha_spaces: true,
                }"
                name="lastName"
                type="text"
              >
              </md-input>

              <span v-show="errors.has('lastName')" class="md-error">
                {{ errors.first("lastName") }}
              </span>
            </md-field>
          </div>

          <!-- Email -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('email') }">
              <label>{{ editRequiredForm($t("create-company.email")) }}</label>

              <md-input
                v-model="company.email"
                v-validate="{
                  email: true,
                }"
                name="email"
                type="email"
              >
              </md-input>

              <span v-show="errors.has('email')" class="md-error">
                {{ errors.first("email") }}
              </span>
            </md-field>
          </div>

          <!-- Numéro de téléphone -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('tel') }">
              <label>{{ $t("create-company.tel") }}</label>

              <md-input
                v-model="company.tel"
                v-validate="'telephone'"
                name="tel"
                type="tel"
              >
              </md-input>

              <span v-show="errors.has('tel')" class="md-error">
                {{ errors.first("tel") }}
              </span>
            </md-field>
          </div>

          <!-- Site Web -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('url') }">
              <label>{{ $t("create-company.url") }}</label>

              <md-input
                v-model="company.url"
                v-validate="'url'"
                name="url"
                type="url"
              >
              </md-input>

              <span v-show="errors.has('url')" class="md-error">
                {{ errors.first("url") }}
              </span>
            </md-field>
          </div>
        </div>
        <div class="meep-form-column">
          <!-- Numéro et voie -->
          <div class="meep-input">
            <md-field>
              <label>Adresse</label>

              <md-input v-model="company.adresse" name="adresse" type="text">
              </md-input>
            </md-field>
          </div>

          <!-- Ville -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('ville') }">
              <label>Ville</label>

              <md-input
                v-model="company.ville"
                v-validate="'alpha_spaces'"
                name="ville"
                type="ville"
              >
              </md-input>

              <span v-show="errors.has('ville')" class="md-error">
                {{ errors.first("ville") }}
              </span>
            </md-field>
          </div>

          <!-- Code postal -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
              <label>Code postal</label>

              <md-input
                v-model="company.codepostal"
                v-validate="'numeric|min:5|max:5'"
                name="codepostal"
                type="text"
              >
              </md-input>

              <span v-show="errors.has('codepostal')" class="md-error">
                {{ errors.first("codepostal") }}
              </span>
            </md-field>
          </div>

          <!-- Groupes -->
          <div class="meep-input">
            <md-field>
              <label for="groups"> Groupes </label>

              <md-select
                id="groups"
                v-model="company.groups"
                multiple
                name="groups"
              >
                <md-option
                  v-for="(group, index) of groups"
                  :key="index"
                  :value="group.id"
                >
                  {{ group.name }}
                </md-option>
                <md-option
                  v-if="groups.length === 0"
                  key="-1"
                  disabled
                  value=""
                >
                  {{ $t("create-company.accune") }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <!-- cloud synchroniser les e-mails -->
          <div v-if="self.organization.has_email_synchro" class="meep-input">
            <md-field
              :class="{ 'md-invalid': errors.has('cloud_synchronize_email') }"
            >
              <label>{{ $t("company.cloud_synchronize_email") }}</label>

              <md-input
                v-model="company.cloud_synchronize_email"
                v-validate="'email'"
                name="cloud_synchronize_email"
                type="text"
              >
              </md-input>

              <span
                v-show="errors.has('cloud_synchronize_email')"
                class="md-error"
              >
                {{ errors.first("cloud_synchronize_email") }}
              </span>
            </md-field>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import groupesModel from "@/model/groupes";
import { mapGetters } from "vuex";
import LoadingCard from "@/components/LoadingCard";

import { compareObjects } from "@/services/util";
import ForceLeaveMixin from "@/mixins/ForceLeaveMixin";

export default {
  name: "CompanyForm",

  mixins: [ForceLeaveMixin],

  components: {
    LoadingCard,
  },

  props: {
    companyData: {
      type: Object,
      default: () => {},
    },
    oldCompanyData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isLoading: false,
      company: this.companyData,

      oldCompany: this.oldCompanyData,

      groups: [],
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isCollab", "isClient", "isJEPA", "self"]),

    isAnyModified() {
      return !compareObjects(this.company, this.oldCompany);
    },

    confirmModalText() {
      switch (this.confirmationContext) {
        default:
          return {
            header: this.$t("Companys.view.modal-title"),
            body: () => {
              return this.$t("Companys.view.edit-modal-body");
            },

            question: this.$t("Companys.view.edit-modal-question"),
          };
      }
    },
  },

  created() {
    this.loadGroups();
  },

  watch: {
    companyData: function() {
      this.company = this.companyData;
    },

    oldCompanyData: function() {
      this.oldCompany = this.oldCompanyData;
    },
  },

  methods: {
    goBack() {
      window.history.back();
    },

    editRequiredForm(stringData) {
      return stringData.slice(0, stringData.length - 1);
    },

    async loadGroups() {
      try {
        this.isLoading = true;

        this.groups = await groupesModel.getAll();

        this.isLoading = false;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });

        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#app .Companys-create {
  &__form.md-layout-item {
    padding-bottom: 60px;
  }

  .select-error {
    color: var(--primary, #ff1744);

    &--small {
      font-size: 12px;
    }
  }

  &__list-item {
    color: var(--bg-primary);
  }

  &__card {
    padding: 0 25%;

    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .create-button {
    margin-top: 50px;
    .md-button {
      padding: 0 30px;
    }
  }
}
</style>
